import React from 'react'
import Helmet from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'
import Image from 'gatsby-image'

import Layout from '../../components/Layout'
import FullPage from '../../components/FullPage'
import WorkTitleBlock from '../../components/WorkTitleBlock'

export const frontmatter = {
  title: 'Mactaker Logo',
  type: 'Identity',
  client: 'Private',
  path: '/works/identity/mactaker-logo/',
  date: '2011-06-08',
  image: 'mactaker-logo-demo',
  work_type: 'Logo',
}

export default ({ location }) => {
  const query = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "mactaker/i/mactaker-logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 90) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )
  
  return (
    <Layout location={location}>
      <Helmet title={frontmatter.title} />

      <FullPage>
        <figure className="text-center">
          <Image
            fluid={query.file.childImageSharp.fluid}
            alt="MacTaker logo"
            style={{
              maxWidth: 800,
              margin: 'auto',
            }}
          />
          
        </figure>

        <p className="aligncenter text-center">
          MacTaker is a website that helps people sell their used Apple
          hardware.
        </p>
      </FullPage>

      <WorkTitleBlock {...frontmatter} />
    </Layout>
  )
}
